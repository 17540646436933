<template>
<div class="wrapper">
  <topbar2></topbar2>
  <header2></header2>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="row">
        <div class="col-md-12">
          <div class="card-header">
            <h3 class="card-title"> Add Role </h3>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2">&nbsp;</div>
          <div class="col-lg-8 connectedSortable">
            <div class="card">
              <div class="card-body">
                <div class="tab-content p-0">
                  <div class="chart tab-pane active">
                    <div>
                      <div class="form-group">
                        <label for="description">Role Name</label>
                        
                        <input
          class="form-control"
          id="role_name"
          required
          v-model="role_name"
          name="role_name"
          placeholder="Role Name"
        />
</div>             
<div class="">
  <div class="radio green" v-for="(item, index) in rolelist" :key="index">
<div v-if="item.parent_id == '0'">
  <label class="radio green mainhead">
  <input type="checkbox" @change="addCat" id="role" v-model="role" name="role" v-bind:value="item.id">
  <span>&nbsp;{{item.name}} </span> </label>
</div>
<div v-else-if="item.parent_id != '0'">
  <label class="radio green subhead">
  <input type="checkbox" @change="addCat" id="role" v-model="role" name="role" v-bind:value="item.id">
  <span>&nbsp;{{item.name}} </span> </label>
</div>  
  </div>  
</div>
                      <hr>
                      <button  v-on:click="submitorder()" class="btn btn-success">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <footer2></footer2>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import Header from './../Header.vue'
import Footer from './../Footer.vue'
import Topbar from './../Topbar.vue'
import axios from "axios";
export default {
  extends:defineComponent,
  components:{
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar    
      },
  name: "add-role",
  data() {
    return {      
        id: null,
        user_id:this.$store.state.auth.user.data[0].id, 
        role_name: "",
        parent_id:'',  
        rolelist:[], 
        role:[],     
        company_id:this.$store.state.auth.user.data[0].company_id        
    };
  },
  methods: {
    addCat: function() {            
          this.list = this.role.toString()   
          console.log(this.list); 
          //var paymentData = this.role.map((m) => m.id);       
          //console.log(paymentData); 
    },
    showsuccessmsg(msgData){
       createToast(msgData,{
          type: 'success',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
     showfailedmsg(msgData){
       createToast(msgData,{
          type: 'danger',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
  async getRolelist() {
      var response = await axios.post(
        `http://laundry.ezeehost.com:4909/ClientIspmateApi/getaction`, {
          company_id: this.company_id, 
          user_id: this.user_id,         
        })      
    
    this.rolelist = response.data.data;     
    console.log(this.rolelist)
    },  
    submitorder(){                       
            axios.post('http://laundry.ezeehost.com:4909/ClientIspmateApi/addroledata', {
              user_id: this.user_id,
              company_id: this.company_id,
              role_name: this.role_name,              
              privilege_list: this.list              
              })                
              .then(response => {   
                  this.showsuccessmsg(response.data.response);                  
                  this.$router.push({path:'/rolelist/'})
                  console.log(response.data);
                  //return response
                }).catch(error => { 
                  this.showfailedmsg('Something went wrong');       
                  console.log(error)        
      });


        },  
   
  },  
  mounted(){          
        this.getRolelist();           
  }
};
</script>

<style> 
  .mainhead {
    background: #F1F3F4;
    padding: 5px 10px;
    width: 100%;
    border: 1px solid #F1F3F4;
    font-weight: 500 !important; 
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
    cursor: pointer;
    }
  .subhead {
    background: #fff;
    cursor: pointer;
    padding: 5px 10px;
    float: left;
    width: 33%;    
    font-weight: 400 !important;
    font-size: 14px;    
    }  
  @import '../../assets/dist/css/custom.css'  
</style>